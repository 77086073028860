<template>
   <div class="profile">
      <div v-if="!userHasOnlyOneModule" class="profile-sidebar">
         <SideBar />
      </div>
      <div class="profile-container" :style="{ width: userHasOnlyOneModule ? '100%' : 'calc(100% - 60px)'}">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <div class="profile-body" :style="{ 'padding-left': userHasOnlyOneModule ? '' : '3%' }">

            <!-- titulo seccion -->
            <div class="profile-body-top">
               <SectionTitle
                  :title="title_component.title"
                  :subtitle="title_component.subtitle"
               />
               <!-- edit image -->
               <div class="img-perfil-edit" v-if="!edit_profile">
                  <span>
                     {{ user.primer_nombre + " " + user.apellido_paterno }}
                  </span>
               </div>
               <div class="img-perfil-edit" v-if="edit_profile">
                  <label class="upload-btn" for="input-file">
                     <input
                        type="file"
                        id="input-file"
                        @change="loadImages($event)"
                        accept="image/jpeg, image/png, image/jpg"
                        style="display: none"
                     />
                     <span>
                        Editar imagen
                        <font-awesome-icon icon="images"></font-awesome-icon>
                     </span>
                  </label>
               </div>
               <div
                  class="img-perfil"
                  @click="!edit_profile && openList()"
                  :style="!edit_profile && 'cursor:pointer'"
               >
                  <img :src="handleUserImage()" alt="" />
               </div>
               <div class="img-perfil-list" v-if="open_list">
                  <div class="border-arrow">
                     <div class="arrow"></div>
                  </div>
                  <ul>
                     <li @click="toDashboard()">Ir al Dashboard</li>
                  </ul>
                  <ul>
                     <li @click="logOut()">Cerrar Sesión</li>
                  </ul>
               </div>
            </div>
            <!-- <div class="background-images">
               <img class="form_1" src="@/assets/img/forma_1.svg" alt="" />
               <img class="form_2" src="@/assets/img/forma_2.svg" alt="" />
            </div> -->
            <!-- contenido -->
            <div class="profile-body-container">
               <div class="user-data">
                  <div class="user-data-title">
                     <h3>Datos del Usuario</h3>
                     <!-- Activar edicion -->
                     <h5 v-if="!edit_profile" @click="activeEditProfile">
                        Editar
                        <font-awesome-icon icon="pen"></font-awesome-icon>
                     </h5>
                  </div>
                  <div class="user-data-container">
                     <div class="user-data-container-column">
                        <div class="profile-element name">
                           <h4>Nombre:</h4>
                           <div class="resp">
                              {{ user.primer_nombre }}
                           </div>
                        </div>
                        <div class="profile-element lastname">
                           <h4>Apellido:</h4>
                           <div class="resp">
                              {{ user.apellido_paterno }}
                           </div>
                        </div>
                        <div class="profile-element dni">
                           <h4>Rut:</h4>
                           <div class="resp">
                              {{ user.rut_usuario }}-{{ user.dv_usuario }}
                           </div>
                        </div>
                        <div class="profile-element phone">
                           <h4>Telefono:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.telefono_movil }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.phone"
                                    maxlength="9"
                                    @keypress="
                                       handleNumberInputs(), handlePhone()
                                    "
                                 />
                              </div>
                           </div>
                        </div>
                        <div class="profile-element email">
                           <h4>Email:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.correo }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.correo"
                                    @keydown.space.prevent
                                    @keypress="handleEmail"
                                    @click="handleEmail"
                                    @keyup.delete="handleEmail"
                                 />
                                 <!-- icono correo valido -->
                                 <span
                                    v-if="show_icon_correo == 'check'"
                                    :content="correo_success_msg"
                                    v-tippy="{ arrow: true }"
                                 >
                                    <font-awesome-icon
                                       icon="check"
                                    ></font-awesome-icon>
                                 </span>
                                 <!-- icono correo no valido -->
                                 <span
                                    v-if="show_icon_correo == 'error'"
                                    :content="correo_error_msg"
                                    v-tippy="{ arrow: true }"
                                 >
                                    <font-awesome-icon
                                       icon="times"
                                    ></font-awesome-icon>
                                 </span>
                                 <!-- loading -->
                                 <div
                                    class="gif-container"
                                    v-if="show_icon_correo == 'load'"
                                 >
                                    <img
                                       src="@/assets/img/spinner.gif"
                                       alt=""
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="profile-element user-type">
                           <h4>Tipo de usuario:</h4>
                           <div class="resp">
                              {{ user.privilegio[0].nombre_tipo }}
                           </div>
                        </div>
                     </div>
                     <div class="user-data-container-column">
                        <div class="profile-element company">
                           <h4>Empresa:</h4>
                           <div class="resp">
                              {{ user.empresa[0].nombre_empresa }}
                           </div>
                        </div>
                        <div class="profile-element workplace">
                           <h4>Lugar de trabajo:</h4>
                           <div class="resp">
                              {{ user.sucursal[0].nombre_sucursal }}
                           </div>
                        </div>
                        <div class="profile-element area">
                           <h4>Departamento:</h4>
                           <div class="resp">
                              {{ user.gerencia[0].nombre_gerencia }}
                           </div>
                        </div>
                        <div class="profile-element anexo">
                           <h4>Anexo:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.anexo }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.anexo"
                                    @keypress="handleAnexo()"
                                    @click="handleAnexo()"
                                    @keyup.delete="handleAnexo()"
                                 />
                              </div>
                           </div>
                        </div>
                        <!-- opcion cambiar contraseña -->
                        <div v-if="edit_profile" class="change-password">
                           <button @click="openModalPass">
                              Cambiar contraseña &nbsp;
                              <font-awesome-icon icon="key"></font-awesome-icon>
                           </button>
                        </div>
                        <div class="buttons" v-if="edit_profile">
                           <!-- cancelar cambios -->
                           <button @click="cancelEdit" class="cancel">
                              Cancelar
                           </button>
                           <!-- Guardar cambios de perfil-->
                           <button
                              @click="active_edit_profile_btn && editProfile()"
                              class="save"
                              :class="active_edit_profile_btn && 'active'"
                           >
                              Guardar cambios
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="user-subscription">
                  <div class="user-subscription-title">
                     <h3>Datos suscripción</h3>
                  </div>
                  <div class="user-subscription-container">
                     <div class="user-subscription-container-column">
                        <div class="subscription-date">
                           <h4>Fecha de suscripción:</h4>
                        </div>
                        <div class="due-date">
                           <h4>Fecha de vencimiento:</h4>
                        </div>
                     </div>
                     <div class="user-subscription-container-column">
                        <div class="subscription-type">
                           <h4>Tipo de suscripción:</h4>
                        </div>
                        <div class="subscription-email">
                           <h4>Email:</h4>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Modal para cambiar contraseña -->
      <div class="modal-change-pass" v-if="open_modal_pass">
         <div class="modal-change-pass-container">
            <div class="title">
               <h3>Cambiar contraseña</h3>
               <div class="close" @click="closeModalPass">
                  <font-awesome-icon icon="times"></font-awesome-icon>
               </div>
            </div>
            <!-- contraseña actual -->
            <div class="current-pass">
               <label for="currentPass">Contraseña actual</label>
               <div class="input-container">
                  <input
                     id="currentPass"
                     type="password"
                     v-model="current_pass"
                     required
                     minlength="5"
                     @mousedown="makePassVisible('currentPass')"
                     @mouseup="hidePass('currentPass')"
                  />
                  <!-- icono para ver contraseña -->
                  <span
                     v-if="show_icon_current == 'eye'"
                     @mousedown="makePassVisible('currentPass')"
                     @mouseup="hidePass('currentPass')"
                  >
                     <font-awesome-icon icon="eye"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña verificada -->
                  <span v-if="show_icon_current == 'check'">
                     <font-awesome-icon icon="check"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña no verificada -->
                  <span v-if="show_icon_current == 'error'">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </span>
                  <!-- gif cargando verificacion -->
                  <div class="gif-container" v-if="show_icon_current == 'load'">
                     <img src="@/assets/img/spinner.gif" alt="" />
                  </div>
               </div>
               <span v-if="current_pass_error.length > 0" class="error-msg">
                  {{ current_pass_error }}
               </span>
            </div>
            <!-- nueva contraseña -->
            <div class="new-pass">
               <label>Nueva contraseña </label>
               <div class="input-container">
                  <input
                     type="password"
                     v-model="new_pass"
                     required
                     disabled
                     id="newPass"
                     ref="newPass"
                     @mousedown="makePassVisible('newPass')"
                     @mouseup="hidePass('newPass')"
                  />
                  <!-- icono para ver contraseña -->
                  <span
                     v-if="show_icon_new == 'eye'"
                     @mousedown="makePassVisible('newPass')"
                     @mouseup="hidePass('newPass')"
                  >
                     <font-awesome-icon icon="eye"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña verificada -->
                  <span v-if="show_icon_new == 'check'">
                     <font-awesome-icon icon="check"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña no verificada -->
                  <span v-if="show_icon_new == 'error'">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </span>
                  <!-- icono cargando verificacion -->
                  <div class="gif-container" v-if="show_icon_new == 'load'">
                     <img src="@/assets/img/spinner.gif" alt="" />
                  </div>
               </div>
               <span v-if="new_pass_error.length > 0" class="error-msg">
                  {{ new_pass_error }}
               </span>
            </div>
            <!-- repite nueva contraseña -->
            <div class="again-pass" v-if="new_pass.length > 0">
               <label>Repite tu contraseña </label>
               <div class="input-container">
                  <input
                     type="password"
                     v-model="new_pass_again"
                     required
                     id="againPass"
                     @mousedown="makePassVisible('againPass')"
                     @mouseup="hidePass('againPass')"
                  />
                  <!-- icono para ver contraseña -->
                  <span
                     v-if="show_icon_again == 'eye'"
                     @mousedown="makePassVisible('againPass')"
                     @mouseup="hidePass('againPass')"
                  >
                     <font-awesome-icon icon="eye"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña verificada -->
                  <span v-if="show_icon_again == 'check'">
                     <font-awesome-icon icon="check"></font-awesome-icon>
                  </span>
                  <!-- icono contraseña no verificada -->
                  <span v-if="show_icon_again == 'error'">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </span>
                  <!-- gif cargando verificacion -->
                  <div class="gif-container" v-if="show_icon_again == 'load'">
                     <img src="@/assets/img/spinner.gif" alt="" />
                  </div>
               </div>
               <span v-if="again_pass_error.length > 0" class="error-msg">
                  {{ again_pass_error }}
               </span>
            </div>
            <!-- guardar cambios -->
            <div class="save-pass">
               <button
                  @click="active_save_btn && editPassword()"
                  :class="active_save_btn && 'active'"
               >
                  Cambiar contraseña
               </button>
            </div>
         </div>
      </div>
      <Spinner v-if="show_spinner" />
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus"
      />
   </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import SectionTitle from "../components/Section/SectionTitle.vue";
import Spinner from "../components/Spinner.vue";
import {
   EDIT_USER_PROFILE,
   EDIT_IMG_PROFILE,
   EDIT_PASSWORD,
   VERIFY_PASS,
} from "@/apollo/mutations";
import Status from "../components/Modales/Status.vue";
import { mapGetters, mapState } from "vuex";

export default {
   components: { SideBar, SectionTitle, Spinner, Status },
   data() {
      return {
         user: this.$ls.get("user"),
         user_edit: {
            phone: "",
            anexo: "",
            correo: "",
            img_base64: "",
            image_format: "",
         },
         title_component: {
            title: "Mi Perfil",
            subtitle: "",
         },
         tab_component: [
            {
               name: "Mi Perfil",
               route: "Perfil",
            },
         ],
         edit_profile: false,
         current_pass: "",
         new_pass: "",
         new_pass_again: "",
         show_icon_current: null,
         show_icon_new: null,
         show_icon_again: null,
         current_pass_error: "",
         new_pass_error: "",
         again_pass_error: "",
         open_modal_pass: false,
         show_spinner: false,
         open_modal_status: false,
         modal_status_msg: "",
         modal_status: true,
         user_img_changed: false,
         active_save_btn: false,
         show_icon_correo: null,
         phone_has_change: false,
         mail_has_change: false,
         anexo_has_change: false,
         correo_error_msg: "",
         correo_success_msg: "",
         active_edit_profile_btn: false,
         user_default_img: require("@/assets/img/user_default_img.svg"),
         open_list: false,
      };
   },
   mounted() {
      this.user = this.$ls.get("user");
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 60000);
   },
   computed: {
      ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),
      ...mapState("sidebar", ["modules"]),

      userPhone() {
         return this.user_edit.phone;
      },
      userAnexo() {
         return this.user_edit.anexo;
      },
      userEmail() {
         return this.user_edit.correo;
      },

   },
   watch: {
      userAnexo() {
         this.handleAnexo();
      },
      userPhone() {
         this.handlePhone();
      },
      current_pass: function () {
         var current_pass_input = document.getElementById("currentPass");
         if (this.current_pass.length > 0) {
            this.show_icon_current = "eye";
            this.current_pass.length > 5 &&
               setTimeout(() => {
                  current_pass_input.type = "password";
                  this.verifyCurrentPass();
               }, 2000);
         } else {
            this.show_icon_current = null;
         }
      },
      new_pass: function () {
         var new_pass_input = document.getElementById("newPass");
         if (this.new_pass.length > 0) {
            this.show_icon_new = "eye";

            if (this.new_pass.length > 5) {
               setTimeout(() => {
                  new_pass_input.type = "password";
                  this.verifyNewPass();
               }, 2000);
            } else {
               this.new_pass_error =
                  "La nueva contraseña debe ser tener al menos 6 caracteres.";
            }
         } else {
            this.show_icon_new = null;
         }
      },
      new_pass_again: function () {
         var again_pass_input = document.getElementById("againPass");
         if (this.new_pass_again.length > 0) {
            this.show_icon_again = "eye";
            if (this.new_pass_again.length > 5) {
               setTimeout(() => {
                  again_pass_input.type = "password";
                  this.verifyNewPassAgain();
               }, 2000);
            } else {
               this.again_pass_error =
                  "La contraseña debe ser tener al menos 6 caracteres.";
            }
         } else {
            this.show_icon_again = null;
         }
      },
   },
   methods: {
      // Cargar nueva imagen de perfil
      loadImages(event) {
         this.show_spinner = true;
         var files = event.target.files;
         if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
               if (
                  files[i].type === "image/jpeg" ||
                  files[i].type === "image/png"
               ) {
                  var reader = new FileReader();
                  var new_img = {
                     src: "",
                     format: "",
                  };
                  reader.onload = (e) => {
                     new_img = {
                        src: `${e.target.result}`,
                        format: `${e.target.result}`.split(",")[0],
                     };
                     this.user_edit.img_base64 = new_img.src;
                     this.user_edit.image_format = new_img.format;
                  };
                  reader.readAsDataURL(files[i]);
               }
            }
         }

         setTimeout(() => {
            this.editProfileImage();
         }, 100);
      },
      // Funcion para mostrar foto de perfil del usuario
      handleUserImage() {
         if (this.user_edit.img_base64 !== "" && this.edit_profile) {
            return this.user_edit.img_base64;
         } else {
            if (
               this.user.imagen_perfil !== null &&
               this.user.imagen_perfil !== "" &&
               this.user.imagen_perfil !== undefined
            ) {
               return this.user.imagen_perfil;
            } else {
               return this.user_default_img;
            }
         }
      },
      // Abrir modal para cambiar contraseña
      openModalPass() {
         this.open_modal_pass = true;
      },
      // Cerrar modal contraseña
      closeModalPass() {
         this.open_modal_pass = false;
         // reset
         this.current_pass = "";
         this.show_icon_current = null;
         this.current_pass_error = "";
         this.new_pass = "";
         this.show_icon_new = null;
         this.new_pass_error = "";
         this.new_pass_again = "";
         this.show_icon_again = null;
         this.again_pass_error = "";
      },
      // Cerar modal status
      closeStatus() {
         this.open_modal_status = false;
         this.show_icon_correo = null;
         this.active_edit_profile_btn = false;
      },
      // Mostrar contraseña
      makePassVisible(input) {
         var input_field = document.getElementById(input);
         input_field.type = "text";
      },
      // Ocultar contraseña
      hidePass(input) {
         var x = document.getElementById(input);
         x.type = "password";
      },
      // Activar opcion editar info perfil
      activeEditProfile() {
         this.edit_profile = true;
         this.open_list = false;
         this.user_edit.phone = this.user.telefono_movil;
         this.user_edit.anexo = this.user.anexo;
         this.user_edit.correo = this.user.correo;
         this.user_edit.imagen_perfil = this.user.imagen_perfil;
      },
      // funcion que maneja los iconos del input correo y lo valida
      handleEmail() {
         if (this.user_edit.correo.length > 0) {
            this.show_icon_correo = "load";
            setTimeout(() => {
               if (this.user_edit.correo !== this.user.correo) {
                  if (this.validUserEmail(this.user_edit.correo)) {
                     this.show_icon_correo = "check";
                     this.mail_has_change = true;
                     this.active_edit_profile_btn = true;
                     this.correo_success_msg = "Correo válido";
                  } else {
                     this.show_icon_correo = "error";
                     this.correo_error_msg = "Correo no válido";
                  }
               } else {
                  this.show_icon_correo = "error";
                  this.correo_error_msg = "Correo es igual al actual";
               }
            }, 2000);
         }
      },
      handlePhone() {
         if (this.user_edit.phone.length > 6) {
            if (this.user_edit.phone !== this.user.telefono_movil) {
               this.phone_has_change = true;
               this.active_edit_profile_btn = true;
            } else {
               this.active_edit_profile_btn = false;
            }
         }
      },
      handleAnexo() {
         if (this.user_edit.anexo !== "") {
            if (this.user_edit.anexo !== this.user.anexo) {
               this.anexo_has_change = true;
               this.active_edit_profile_btn = true;
            } else {
               this.active_edit_profile_btn = false;
            }
         }
      },
      // Funcion para permitir solo numeros en el input de telefono
      handleNumberInputs(evt) {
         evt = evt ? evt : window.event;
         var charCode = evt.which ? evt.which : evt.keyCode;
         if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46
         ) {
            evt.preventDefault();
         } else {
            return true;
         }
      },
      // Validar formato de mail
      validUserEmail(email) {
         return String(email)
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
      },
      // Valida contraseña actual
      verifyCurrentPass() {
         this.show_icon_current = "load";
         if (this.current_pass.length > 5) {
            var new_pass_input = document.getElementById("newPass");
            var b64_pass = this.current_pass;
            for (let index = 0; index < 3; index++) {
               b64_pass = btoa(b64_pass);
            }
            this.$apollo
               .mutate({
                  mutation: VERIFY_PASS,
                  variables: {
                     id_usuario: this.user.id_usuario,
                     password: b64_pass,
                  },
               })
               .then((res) => {
                  var resp = res.data.confirmCurrentPassword;
                  setTimeout(() => {
                     if (resp.verify) {
                        this.show_icon_current = "check";
                        new_pass_input.disabled = false;
                        this.current_pass_error = "";
                        this.$refs.newPass.focus();
                     } else {
                        this.show_icon_current = "error";
                        this.current_pass_error =
                           "La contraseña ingresada no es correcta.";
                     }
                  }, 200);
               })
               .catch((err) => console.log(err));
         }
      },
      // Valida contraseña nueva
      verifyNewPass() {
         this.show_icon_new = "load";
         var again_pass_input = document.getElementById("againPass");
         if (this.show_icon_current == "check" && this.new_pass.length > 5) {
            if (this.new_pass !== this.current_pass) {
               this.show_icon_new = "check";
               again_pass_input.disabled = false;
               this.new_pass_error = "";
            } else {
               this.show_icon_new = "error";
               this.new_pass_error =
                  "La nueva contraseña debe ser diferente a la actual.";
            }
         } else {
            this.new_pass_error =
               "La nueva contraseña debe ser tener al menos 6 caracteres.";
            this.show_icon_new = "error";
         }
      },
      // Valida contraseña repetida
      verifyNewPassAgain() {
         this.show_icon_again = "load";
         if (
            this.new_pass_again.length > 5 &&
            this.show_icon_current == "check" &&
            this.show_icon_new == "check"
         ) {
            if (this.new_pass_again === this.new_pass) {
               this.show_icon_again = "check";
               this.again_pass_error = "";
               this.active_save_btn = true;
            } else {
               this.show_icon_again = "error";
               this.again_pass_error =
                  "Las nuevas contraseñas deben ser iguales.";
            }
         } else {
            this.show_icon_again = "error";
            this.again_pass_error =
               "La contraseña debe ser tener al menos 6 caracteres.";
         }
      },
      // Editar contraseña
      editPassword() {
         this.show_spinner = true;
         if (
            this.show_icon_current == "check" &&
            this.show_icon_new == "check" &&
            this.show_icon_again == "check"
         ) {
            var pass_b64 = this.new_pass;
            for (let index = 0; index < 3; index++) {
               pass_b64 = btoa(pass_b64);
            }
            this.$apollo
               .mutate({
                  mutation: EDIT_PASSWORD,
                  variables: {
                     id_usuario: this.user.id_usuario,
                     password: pass_b64,
                  },
               })
               .then((res) => {
                  var resp = res.data.editPasswordUsuario;
                  if (resp.password == null) {
                     this.show_spinner = false;
                     this.open_modal_status = true;
                     this.modal_status = true;
                     this.modal_status_msg = "Contraseña modificada con éxito";
                     this.closeModalPass();
                  }
               })
               .catch((err) => {
                  console.log(err);
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = false;
                  this.modal_status_msg =
                     "Ha ocurrido un problema, intenta nuevamente";
                  this.closeModalPass();
               });
         }
      },
      // Editar imagen de perfil
      editProfileImage() {
         var format = this.user_edit.image_format.split("/")[1].split(";")[0];
         var src = this.user_edit.img_base64.split(",")[1];
         this.$apollo
            .mutate({
               mutation: EDIT_IMG_PROFILE,
               variables: {
                  id_usuario: this.user.id_usuario,
                  imagen_perfil: src,
                  formato: format,
                  id_empresa_fk: this.user.empresa[0].id_empresa,
               },
            })
            .then((res) => {
               var resp = res.data.editImagenPerfil;
               // console.log("editProfileImage", resp);
               // console.log("this.user.imagen_perfil", this.user.imagen_perfil);

               this.user_img_changed = resp.imagen_perfil;
               this.user.imagen_perfil = resp.imagen_perfil;
               this.user_edit.img_base64 = resp.imagen_perfil;
               this.show_spinner = false;
               this.open_modal_status = true;
               this.modal_status = true;
               this.modal_status_msg = "Imagen cambiada con éxito";
            })
            .catch((err) => {
               console.log(err);
               this.open_modal_status = true;
               this.modal_status_msg =
                  "Ha ocurrido un problema, intenta nuevamente";
               this.modal_status = false;
               this.show_spinner = false;
            });
      },
      // Editar perfil
      editProfile() {
         this.show_spinner = true;

         if (
            this.anexo_has_change ||
            this.phone_has_change ||
            this.mail_has_change
         ) {
            this.$apollo
               .mutate({
                  mutation: EDIT_USER_PROFILE,
                  variables: {
                     id_usuario: this.user.id_usuario,
                     correo: this.user_edit.correo,
                     telefono_movil: this.user_edit.phone,
                     anexo: this.user_edit.anexo,
                     telefono_fijo: "",
                  },
               })
               .then((res) => {
                  var resp = res.data.editPerfilUsuario;
                  this.edit_profile = false;
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = true;
                  this.modal_status_msg = "Cambios guardados exitosamente";
                  // Actualizar usuario
                  this.$ls.remove("user");
                  this.$ls.set("user", resp);
                  this.user = this.$ls.get("user");
                  // Si hubo un cambio de imagen se agrega al obj usuario editado
                  if (this.user_img_changed !== null) {
                     this.user.imagen_perfil = this.user_img_changed;
                  }
               })
               .catch((err) => {
                  console.log(err);
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = false;
                  this.modal_status_msg =
                     "Ha ocurrido un problema, intenta nuevamente";
               });
         } else {
            this.show_spinner = false;
         }
      },
      // cancelar edicion
      cancelEdit() {
         this.edit_profile = false;
         this.show_icon_correo = null;
         this.active_edit_profile_btn = false;
      },
      // Cerrar sesi{on
      logOut() {
         this.$router.push({ name: "Login" });
         this.$ls.remove("token");
         this.$ls.remove("user");
      },
      // abrir y cerrar 'lista' que muestra opcion cerrar sesion
      openList() {
         if (this.open_list) {
            this.open_list = false;
         } else {
            this.open_list = true;
         }
      },
      toDashboard() {
         if (this.userHasOnlyOneModule) {
            this.redirectToLoneModule(this.modules);
         } else {
            this.$router.push({ name: "Inicio" });
         }
      }
   },
};
</script>

