<template>
   <div class="section-title">
      <div class="title" v-if="title !=''">
         <h1>
            {{ title }}
         </h1>
      </div>
      <div class="subtitle" v-if="subtitle !=''">
         <h3>
            {{ subtitle }}
         </h3>
      </div>
   </div>
</template>

<script>

export default {
   props: {
      title:    { require: true },
      subtitle: { require: true },
   },
};
</script>
